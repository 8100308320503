
	@import '../../../../CSS/_variables.scss';

	.Colour {
		flex: 1;
		display: inline-block;

		.ColourVisual {
			vertical-align: top;
			width: 6.5rem;
			height: 6.5rem;
			margin-right: 0.5rem;
			border-radius: 50%;

			&.BorderVisible{
				border: 1px solid $Grey;
			}
		}

		.ColourValuesWrapper{
			display: inline-block;

			&.Top{
				margin-bottom: 1rem;
			}
		}

		span {
			display: block;
			@include FontSize('N');
		}
	}

	.PrimaryColours{
		.Colour{
			.ColourVisual{
				width: 6.5rem;
				height: 6.5rem;
			}

			&.ColourFirst{
				.ColourVisual{
					width: 13.5rem;
					height: 13.5rem;
				}
			}
		}
	}

	.SecondaryColours{
		.Colour{
			.ColourVisual{
				width: 3rem;
				height: 3rem;
			}

			&.ColourFirst{
				.ColourVisual{
					width: 6.5rem;
					height: 6.5rem;
				}
			}
		}
	}
