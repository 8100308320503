
	@import './node_modules/@studyportals/form-generator/src/sass/FormGenerator';

	.PackageLinks {
		margin-top: 2rem;

		.PackageLinkWrapper {
			margin-right: 1rem;
		}
	}

	.FgForm {
		margin-top: 3rem;

		#ExampleId {
			width: 320px;
		}
	}
