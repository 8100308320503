
	@import '../../../../CSS/_variables.scss';

	.DownloadLogos{
		.DownloadLogosWrapper{
			display: flex;
			flex-wrap: wrap;

			.DownloadLogo{
				margin: 2rem 1rem 0 0;
			}
		}
	}
