
	@import './CSS/_variables.scss';

	.Wrapper {
		display: flex;
		flex-direction: column;

		.ExpandButton {
			margin: 1rem auto;
		}
	}

	.CodeSnippetWrapper {
		box-sizing: border-box;
		max-width: 100%;
		padding: 1rem;

		border: 1px solid $Grey;

		background: $GreyLL;

		.CodeSnippetsTabs {
			display: flex;

			.TabButton,
			.CopyButton {

				padding: 0.3rem 0;
				margin-bottom: 0.5rem;

				background: none;
				border: 0;

				@include FontSize(N);
				color: $GreyD;
			}

			.TabButton {
				margin: 0 1.5rem 0.5rem 0;

				&.Active {
					color: $BrandBlue;

					border-bottom: 2px solid $BrandBlue;
				}
			}

			.CopyButton {
				margin-left:  auto;

				color: $BrandBlue;
			}
		}

		.CodeSnippetView {

			pre[class*="language-"] {
				position: relative;

				min-height: 200px;
				max-height: 200px;
				overflow-y: hidden;
				overflow-x: scroll;

				background: $White;

				transition: max-height 0.5s;

				&:after {
					position: absolute;
					bottom: 0;
					left: 0;

					display: block;
					width: 100%;
					height: 80px;

					background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,  rgba(255,255,255,1) 100%); 

					content: "";
				}
			}

			&.Expanded {

				pre[class*="language-"] {
					height: auto;
					max-height: 1000px;

					&:after {
						background: none;
					}
				}
			}
		}
	}
