
	@import '../../../../CSS/_variables.scss';

	.ExampleLogos {

		.ExampleLogoWrapper {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			&.ExampleLogoWrapperBordered {
				border: 1px solid $Grey;
			}
		}
	}
