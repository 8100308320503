
    @import '../../../../CSS/_variables.scss';

    $sidebarMargin: 1.5rem;

    svg.defs {
        height: 0;
        width: 0;
        margin: 0;
        padding: 0;
    }

    .Sidebar {
        font-size: 0.875rem;
        padding: 1rem 0 2rem 0;
        box-sizing: border-box;

        ul {
            padding: 0;
        }

        .ComponentLinks {
            padding-left: 5.5rem;
            background: transparent url("http://monet-prtl-co.imgix.net/StyleGuide/BrandIdentityGraphics.svg") no-repeat left 1rem;
        }

        ul ul {
            margin: 0 0 1.5rem 0;
            padding-left: 1.5rem;
        }

        li {
            margin: 0 0 0.5rem 0;
            list-style: none;

            &.Group {
                margin-top: 1rem;

                &:first-of-type{
                    margin-top: 0;
                }
            }

            .GroupHeader {
                display: block;
                margin: 0 0 0.5rem 0;
                color: $Grey;

				@include HeadingSize('H5');
            }
        }

        .NavLink {
            display: block;
            position: relative;
            color: $GreyDD;
            text-decoration: none;

	        @include FontSize('N');

            &:visited {
                color: $GreyDD;
            }

            &.router-link-exact-active,
            &:hover {
                 font-weight: 600;
                 text-decoration: none;
                 color: $GreyDD;
            }

            &.router-link-exact-active {
                cursor: default;
                pointer-events: none;
            }
        }

        .NavLinkLogo {
            text-align: center;
            margin: 0 auto 2rem auto;

            .StyleGuideLogo {
                width: 13rem;
            }
        }

        .SidebarNavSections{
            margin: 0 1.5rem 1.5rem 2rem;

            li {
                margin-bottom: 0.5rem;

                a {
                    display: flex;
                    align-items: center;
                }
            }

            i {
                font-size: 1rem;
                margin-right: 0.5rem;
            }
        }

        .NavLinkDesignPrinciples,
        .NavLinkUserJourney{
            margin: 0 1.5rem 0.5rem 2rem;
            padding-left: 1.5rem;

            i {
                position: absolute;
                top: 5px;
                left: 0;
            }
        }

        .NavLinkUserJourney{
            margin-bottom: 1.5rem;

            i {
                visibility: hidden
            }
        }

        .AboutLinks {
			display: flex;
            padding-left: 0;
            margin: 4rem 1.5rem 2rem 2rem;

            .AboutLink {
                display: inline-block;
				margin-bottom: 0;

				&:first-child {
					flex-grow: 1;
				}
            }

            .AboutUs {
                display: block;
                margin: 0;
                color: $Grey;
	            @include HeadingSize('H5');
                line-height: 3.2rem;
            }

            .NavLink {
                display: inline-block;
                position: relative;
                height: 3rem;
                width: 3rem;
                color: $GreyDD;
				margin-left: 0.5rem;
                line-height: 3rem;
                text-align: center;
                text-decoration: none;
                font-weight: 700;
                border-radius: 3px;
                background: $White;
                transition: background .5s;
                border: 2px solid $GreyDD;

                svg.embed {
                    height: 1.8rem;
                    width: 1.8rem;
                    padding-top: 8px;
                    vertical-align: top;
                    fill: $GreyDD;
                    transition: background .5s;
                }

                img {
                    height: 1.8rem;
                    padding-top: 8px;
                    vertical-align: top;
                }

                i {
                    margin-right: 0;
                    color: $GreyDD;
                    font-size: 1.3rem;
                    vertical-align: middle;
                }

                &:hover {
                    color: $White;
                    font-weight: 700;
                    background: $GreyDD;

                    svg.embed {
                        fill: $White;
                    }
                }

                &.router-link-exact-active {
                    color: $White;
                    font-weight: 700;
                    background: $GreyDD;

                    svg.embed {
                        fill: $White;
                    }
                }
            }
        }

        .NavLinkDownloadBrandbook {
            display: block;
            position: relative;
            margin: 1rem 1.5rem 1rem 2rem;
            padding: 0 0 0 2.3rem;
            line-height: 2.5rem;
            font-size: 1rem;
            border: 2px solid $GreyDD;
            border-radius: 3px;
            transition: background .5s;

            i {
                position: absolute;
                top: 0;
                left: 0;
                width: 2.5rem;
                font-size: 1.3rem;
                line-height: inherit;
                text-align: center;
            }

            &:hover {
                color: $White;
                font-weight: normal;
                background: $GreyDD;
            }
        }
    }
