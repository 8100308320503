body{
	/* Ordered and unordered lists. */
	ul{
		list-style-type: square;

		li{
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			&:first-child{
				margin-top: 0;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	ol{
		li{
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			&:first-child{
				margin-top: 0;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}