
	@import '../../../CSS/_variables.scss';

	#Modal{
		.VisualBlock{
			margin: 3rem 0 0;

			img {
				max-width: 100%;
			}

			figcaption {
				max-width: 100%;
			}
		}
		.ModalAnimations {
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-column-gap: 3rem;
			margin-bottom: 1rem;
		}
		.MobileExamples {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 3rem;

			.VisualBlock {
				img {
					margin: 0 auto;
				}
			}
		}
		.PackageLinks {
			margin-top: 2rem;

			.PackageLinkWrapper {
				margin-right: 1rem;
			}
		}
		.ExampleIntro{
			width: 100%;
			text-align: center;
			margin: 1rem;
		}
		.Motion {
			h3 {
				margin: 3rem 0;
			}

			p:first-of-type {
				margin-top: 0;
			}
		}
	}
