
	@import '../../../CSS/_variables.scss';

	#Writing{
		h3{
			margin-bottom: $SpaceL;
		}

		.SubItem{
			max-width: 60rem;
		}

		.ToneOfVoiceShape{
			position: relative;
			width: 16rem;
			height: 16rem;
			margin: 0 auto 3rem;
		}

		.ShapeLabel{
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: $FirstFloor;
			transform: translate(-50%, -50%);
			color: $White;

			@include HeadingSize('H2');
		}

		.ShapeImg{
			z-index: $GroundFloor;
			width: 16rem;
			height: 16rem;
		}

		.ThreeWritingRules{
			margin: $SpaceL 0 0 0;
			padding: 0 0 0 1rem;

			li{
				margin: 0;
			}
		}

		.ToneOfVoiceWrapper{
			flex: 3;
			margin: 1rem 1rem 1rem 0;
		}

		.ButtonsExample{
			> *{
				margin-right: 1rem;
				margin-bottom: 0.5rem;
			}
		}

		.SpellingInformation{
			margin-bottom: 0;
		}

		.SpellingExamples{
			display: inline-block;
			margin-right: 2rem;

			.EnglishList{
				list-style-type: none;
				color: $GreyDD;
				margin: 0;
				padding: 0;

				.Spelling{
					background: $GreyLLL;
					padding: 0.2rem 1rem;
					display: inline-block;
					width: 5rem;

					@include FontSize('SN');
				}

				&.Warning{
					.Spelling{
						background: $RedLL;
					}
				}
			}
		}

		.DegreeLabels, .UniversityLabels, .WordLabels{
			display: inline-block;
			margin: 0 0.5rem 0.5rem 0;

			@include FontSize('SN');
		}

		.CommonWords{
			margin: 0 0 $SpaceL 0;
		}
	}
