
	@import '../../../../CSS/_variables.scss';

	#Logo{
		h3{
			margin-bottom: $SpaceL;
		}

		.Example{
			padding-left: 0;
		}

		.DownloadMainLogo{
			max-width: 38.5rem;
			.DownloadImage {
				margin-top: -6%;
				margin-bottom: -6%;
			}
		}

		.UsageLogo{
			max-width: 30rem;
			margin-bottom: 0;
		}

		.RestrictionLogos{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.RestrictionLogo{
				flex: 2;
				min-width: 46%;
				margin: 1rem 1rem 1rem 0;
			}
		}
	}
