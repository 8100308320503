
	@import '../CSS/_variables.scss';

	$triggerWidth: 2rem;
	$triggerHeight: 3.5rem;

	#StyleGuide {
		height: 100vh;

		.NavContainer{
			position: fixed;
			z-index: 25;
			width: 18rem;
			height: 100vh;
			background-color: $White;
			border-right: 1px solid $GreyL;
			transition: width .5s;
		}

		.SidebarContainer {
			position: absolute;
			right: 0.5rem;
			height: 100vh;
			width: 17.5rem;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 3px;
			}
		}

		.MainContainer {
			min-height: 100vh;
			padding: 0 0 0 18rem;
			background-color: $White;
			transition: padding .5s;

			@include Breakpoint(Small, Medium){
				padding: 0 0 0 1rem;
			}
		}

		.HeaderWrapper {
			box-sizing: border-box;
			width: 100%;
			max-width: inherit;
			box-shadow: 0 0 7px 0 rgba(0,0,0,0.3);
		}

		.ContentWrapper{
			@include Breakpoint(Small){
				padding: $SpaceS;
			}

			@include Breakpoint(Medium){
				padding: $SpaceN;
			}


			@include Breakpoint(Large){
				padding: $SpaceL;
			}

			@include Breakpoint(ExtraLarge){
				padding: $SpaceXL;
			}
		}

		#collapseTrigger {
			position: absolute;
			top: 0;
			left: calc(100% - #{$triggerWidth});
			height: $triggerHeight;
			width: $triggerWidth;
			background: none;
			z-index: $FirstFloor;

			.collapseIcon {
				display: block;
				height: inherit;
				line-height: $triggerHeight;
				width: $triggerWidth;
				margin-left: 0;
				margin-right: 0;
				color: $GreyDD;
				font-size: 1.6rem;
				text-align: center;

				&::before {
					display: block;
					transition: transform 1.5s;
				}
			}

			&:hover{
				cursor: pointer;
				 background: $White;

				.collapseIcon::before {
					font-weight: bold;
				}
			}
		}
	}

	#StyleGuide.navCollapsed {
		.NavContainer{
			width: 2rem;

			#collapseTrigger .collapseIcon::before {
				transform: rotateY(180deg);
			}
		}

		#collapseTrigger {
			height: 100%;
		}

		.MainContainer {
			padding: 0 0 0 2rem;

			@include Breakpoint(Small, Medium){
				padding: 0 0 0 1rem;
			}
		}
	}

