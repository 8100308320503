@import './../../../../node_modules/@studyportals/styles-abstracts/abstracts';

.InfoIcon{
	cursor: pointer;

	&:before{
		display: inline-block;
	}
}

.InfoToolTip{
	/* In some browsers IcoMoon overrules the font of the tooltip content. */
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	display: none;

	&.is-Active{
		display: block;
		width: 250px;
		position: fixed;
		transform: translateX(-50%);
		z-index: $FourthFloor;
	}

	.InfoToolTipContent{
		@include FontSize('SN');
		position: relative;
		display: block;
		background: $White;
		box-shadow: 0 1px 5px rgba($GreyDD, 0.5);
		border-radius: 3px;
		color: $GreyD;
		text-align: center;
		margin: 0;
		padding: 0.75rem;
		box-sizing: border-box;

		&:before{
			display: block;
			content: ' ';
			position: absolute;
			top: -0.5rem;
			height: 0.8rem;
			left: calc(50% - 0.5rem);
			transform: rotate(45deg);
			width: 0.8rem;
			background-color: $White;
			border-top: 1px solid rgba($GreyDD, 0.2);
			border-left: 1px solid rgba($GreyDD, 0.2);
		}
	}

	&.NoseRight{
		transform: none;

		.InfoToolTipContent:before{
			left: calc(100% - 1rem);
		}
	}

	&.NoseLeft{
		transform: none;

		.InfoToolTipContent:before{
			left: 0.5rem;
		}
	}

	&.NoNose{
		transform: none;

		.InfoToolTipContent:before{
			display: none;
		}
	}

	&.NoseBottom{
		.InfoToolTipContent:before{
			top: auto;
			bottom: calc(-0.4rem - 1px);
			transform: rotate(225deg)
		}
	}
}