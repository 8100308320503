
	@import '../../../../CSS/_variables.scss';

	.ExampleLogo{

		flex: 1 1 auto;
		position: relative;
		min-width: 12rem;

		&.Grey{
			background-color: $GreyLL;
		}
		&.Orange{
			background-color: $BrandOrange;
		}
		&.Blue{
			background-color: $BrandBlue;
		}
		&.LightBg{
			background-image: url("http://monet-prtl-co.imgix.net/StyleGuide/Logos/ApplicationLightBackground.png");
		}
		&.DarkBg{
			background-image: url("http://monet-prtl-co.imgix.net/StyleGuide/Logos/ApplicationDarkBackground.png");
		}
	}
