/**
 * @file Galactus.scss
 *
 * Galactus - Devourer of worlds and lay-outs (https://www.youtube.com/watch?v=6pyNEJAiNCU).
 *
 * This file provides a 12 column responsive grid with multiple variations.
 * For a full explanation see: https://github.com/studyportals/Shared/wiki/Galactus-responsive-grid.
 *
 * Copyright 2007-2018 StudyPortals B.V. <http://www.studyportals.com>
 * Redistribution and use in source and binary forms, with or without modification, are permitted provided that the following conditions are met:
 *
 * 1. Redistributions of source code must retain the above copyright notice, this list of conditions and the following disclaimer.
 *
 * 2. Redistributions in binary form must reproduce the above copyright notice, this list of conditions and the following disclaimer in the documentation and/or other materials provided with the distribution.
 *
 * 3. Neither the name of the copyright holder nor the names of its contributors may be used to endorse or promote products derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
*/

@import './../node_modules/@studyportals/styles-abstracts/abstracts';

@import './Reset';

/*
--------------------------------------------------------------------------------
	Galactus 12 column grid
--------------------------------------------------------------------------------
*/

/*
	Calculates the width for the given amount of columns.
	Formula: (100% - gutter) * amount of columns / 12 columns - gutter
*/

@function CalculateColumns($HorizontalGutter, $Multiplier){

	@return calc((100% - #{$HorizontalGutter}) * #{$Multiplier} / 12 - #{$HorizontalGutter});
}

/*
	Calculates the position for the given amount of columns.
	Formula: (100% - gutter) * amount of columns / 12 columns + gutter
*/

@function CalculatePosition($HorizontalGutter, $Multiplier){

	@return calc((100% - #{$HorizontalGutter}) * #{$Multiplier} / 12 + #{$HorizontalGutter});
}

@mixin VerticalMargin(){
	margin-top: 1rem;
	margin-bottom: 1rem;
}

@mixin VerticalPadding(){
	padding-top: 1rem;
	padding-bottom: 1rem;
}

$HorizontalGutter: 1rem;
$Wrapped: 1220px;
$ReadabilityWrapped: 960px;
$MinimumWidth: 320px;

@mixin Galactus{
	position: relative;
	min-width: $MinimumWidth; /* To make sure that the lay-out does not break on low resolution screens. */
	width: 100%;
	@include FontSize('N');
	word-wrap: break-word;
	margin: 0;
	padding: 0;
	padding-top: 3.75rem; /* Make room for fixed header */
	box-sizing: border-box;
	overflow: hidden;

	.col1, .col2, .col2-right, .col2-left, .nav-bar:after{
		clear: both;
	}

	.col1, .col2, .col2-right, .col2-left{
		@include VerticalPadding;
	}

	.nav-bar{
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: $FourthFloor;
		margin: 0;
		padding: 0;

		.colblock{
			padding: 0;
			margin: 0 -100% 0 0;
			width: 100%;
			float: left;
			box-sizing: border-box;
		}
	}

	.col1:after, .col2:after, .col2-right:after, .col2-left:after, .nav-bar:after{
		content: "";
		clear: both;
		display: block;
	}

	.col1 .colblock, .col2 .colblock, .col2-right .colblock, .col2-left .colblock{
		float: left;
		margin-right: -100%;
		overflow: hidden;
		box-sizing: border-box;
	}

	.col1 .colblock:nth-child(n+1), .col2 .colblock:nth-child(n+1), .col2-right .colblock:nth-child(n+1), .col2-left .colblock:nth-child(n+1){
		margin-left: 0;
		@include VerticalMargin;
		clear: both;
	}

	@include Breakpoint(Small){
		.col1 > .colblock{
			float: left;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
		}

		.col2 > .colblock{
			float: left;
			margin-right: -100%;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
			@include VerticalMargin;
			clear: both;
		}

		.col2-right > .colblock{
			float: left;
			margin-right: -100%;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
			@include VerticalMargin;
			clear: both;
		}

		.col2-left > .colblock{
			float: left;
			margin-right: -100%;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
			@include VerticalMargin;
			clear: both;
		}
	}

	@include Breakpoint(Medium){
		.col1 > .colblock{
			float: left;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
		}

		.col2 > .colblock{
			float: left;
			margin-right: -100%;
			width: CalculateColumns($HorizontalGutter, 6);
			@include VerticalMargin;
		}

		.col2 > .colblock:nth-child(2n+1){
			margin-left: $HorizontalGutter;
			clear: both;
		}

		.col2 > .colblock:nth-child(2n+2){
			margin-left: CalculatePosition($HorizontalGutter, 6);
			clear: none;
		}

		.col2-right > .colblock{
			float: left;
			margin-right: -100%;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2* #{$HorizontalGutter}));
			@include VerticalMargin;
			clear: both;
		}

		.col2-left > .colblock{
			float: left;
			margin-right: -100%;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
			@include VerticalMargin;
			clear: both;
		}
	}

	@include Breakpoint(Large){
		.col1 > .colblock{
			float: left;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
		}

		.col2 > .colblock{
			float: left;
			margin-right: -100%;
			width: CalculateColumns($HorizontalGutter, 6);
			@include VerticalMargin;
		}

		.col2 > .colblock:nth-child(2n+1){
			margin-left: $HorizontalGutter;
			clear: both;
		}

		.col2 > .colblock:nth-child(2n+2){
			margin-left: CalculatePosition($HorizontalGutter, 6);
			clear: none;
		}

		.col2-right > .colblock{
			float: left;
			margin-right: -100%;
			width: CalculateColumns($HorizontalGutter, 4);
			@include VerticalMargin;
		}

		.col2-right > .colblock:nth-child(2n+1){
			margin-left: CalculatePosition($HorizontalGutter, 4);
			clear: both;
			width: CalculateColumns($HorizontalGutter, 8);
		}

		.col2-right > .colblock:nth-child(2n+2){
			margin-left: $HorizontalGutter;
			clear: none;
		}

		.col2-left > .colblock{
			float: left;
			margin-right: -100%;
			width: CalculateColumns($HorizontalGutter, 8);
			@include VerticalMargin;
		}

		.col2-left > .colblock:nth-child(2n+1){
			margin-left: $HorizontalGutter;
			clear: both;
		}

		.col2-left > .colblock:nth-child(2n+2){
			margin-left: CalculatePosition($HorizontalGutter, 8);
			clear: none;
			width: CalculateColumns($HorizontalGutter, 4);
		}
	}

	@include Breakpoint(ExtraLarge){
		.col1 > .colblock{
			float: left;
			margin-left: $HorizontalGutter !important;
			width: calc(100% - (2 * #{$HorizontalGutter}));
		}

		.col2 > .colblock{
			float: left;
			margin-right: -100%;
			width: CalculateColumns($HorizontalGutter, 6);
			@include VerticalMargin;
		}

		.col2 > .colblock:nth-child(2n+1){
			margin-left: $HorizontalGutter;
			clear: both;
		}

		.col2 > .colblock:nth-child(2n+2){
			margin-left: CalculatePosition($HorizontalGutter, 6);
			clear: none;
		}

		.col2-right > .colblock{
			float: left;
			margin-right: -100%;
			width: CalculateColumns($HorizontalGutter, 4);
			@include VerticalMargin;
		}

		.col2-right > .colblock:nth-child(2n+1){
			margin-left: CalculatePosition($HorizontalGutter, 4);
			clear: both;
			width: CalculateColumns($HorizontalGutter, 8);
		}

		.col2-right > .colblock:nth-child(2n+2){
			margin-left: $HorizontalGutter;
			clear: none;
		}

		.col2-left > .colblock{
			float: left;
			margin-right: -100%;
			width: CalculateColumns($HorizontalGutter, 8);
			@include VerticalMargin;
		}

		.col2-left > .colblock:nth-child(2n+1){
			margin-left: $HorizontalGutter;
			clear: both;
		}

		.col2-left > .colblock:nth-child(2n+2){
			margin-left: CalculatePosition($HorizontalGutter, 8);
			clear: none;
			width: CalculateColumns($HorizontalGutter, 4);
		}
	}

	/*
	----------------------------------------------------------------------------
		Additional/ exceptional styling
	----------------------------------------------------------------------------
	*/

	img{
		max-width: 100%;
		width: auto; /* IE8 fix. */
		height: auto;
	}

	/* To really force the overriding of properties we use !important, there is no way around it. */
	.no-top-margin{
		margin-top: 0 !important;
	}

	.no-bottom-margin{
		margin-bottom: 0 !important;
	}

	.no-vertical-margin{
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.no-top-padding{
		padding-top: 0 !important;
	}

	.no-bottom-padding{
		padding-bottom: 0 !important;
	}

	.no-vertical-padding{
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.col1, .col2, .col2-right, .col2-left{
		/* Attaches an page wrapper for all the row variations */
		&.wrapped{
			max-width: $Wrapped;
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
		}

		& .colblock.no-horizontal-margin{
			width: 100%;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	.col1{
		.colblock{
			/*
				Attaches an element wrapper for all 'colblock' elements within
				the row variations. This wrapper is only
				intended for improving readability, not as a page wrapper.
			*/

			.readability-wrapped{
				max-width: $ReadabilityWrapped;
				margin: 0 auto;
			}
		}
	}
}

#galactus{
	@include Galactus;
}