
    @import '../../../../CSS/_variables.scss';

    #PrinciplesPrinciples .Principle .PrincipleIcon{
        margin-right: 0.5rem;
        font-size: 2rem;
        line-height: 2.5rem;
        vertical-align: text-bottom;
    }

    #PrinciplesPrinciples .PrincipleRule{
        display: block;
        color: $GreyDD;
        margin-bottom: 0.5rem;
        @include HeadingSize('H5');
    }

    #PrinciplesPrinciples .PrincipleRuleDescription{
        color: $GreyD;
        margin-bottom: 1.5rem;
        max-width: 30rem;
    }
