html, body, .colblock, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s,
samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, th, article,
aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav,
output, ruby, section, summary, time, mark, audio, video, p{
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	line-height: 1.5em;
}

html{
	font-size: 16px; /* To make sure that our "rem" sizing works properly. */
}

body{
	font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section{
	display: block;
}

a:active{
	outline: none;
}

:focus{
	-moz-outline-style: none;
	outline: none;
}

blockquote, q{
	quotes: none;
}

blockquote::before, blockquote::after, q::before, q::after{
	content: none;
}

table{
	border-collapse: collapse;
	border-spacing: 0;
}

/* Removing the 'custom' appearance of form fields to resemble native form fields on webkit devices. */
textarea, input[type="text"], input[type="number"], input[type="date"],
input[type="email"], input[type="password"], input[type="search"], input[type="submit"],
input[type="tel"], button{
	-webkit-appearance: none;
}

input, textarea, select{
	border-radius: 0;
}

/* Make sure all form elements inherit the font-family set on page level. */
input, textarea, select, option, button{
	font-family: inherit;
}