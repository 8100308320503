
	@import '../../../CSS/_variables.scss';
	@import '@studyportals/styles-components/components/Button.scss';

	button:hover{
		cursor: pointer;
	}

	.AlignLeft{
		align-items: left;
	}

	#Writing{
		.buttonHeader{
			margin-bottom: 3rem;
		}
	}
	.ChampionButton{

		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		&.NoHover:hover{
			background: $BrandOrange;
		}
	}

	.DriverButton{

		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		&.NoHover:hover{
			color: $White;
			background-color: $BrandOrange;
			border-color: $BrandOrange;
		}

		&.Blue{

			&.NoHover:hover{
				color: white;
				background: $BrandBlue;
				border-color: $BrandBlue;
			}
		}

		&.OnDarkBackground{

			&.NoHover:hover{
				background: transparent;
				border-color: white;
				color: white;
			}
		}
	}

	.NavigatorButton{
		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		@include Breakpoint(Large, ExtraLarge){

			&.ShowTouchSurface{
				padding: 1rem / 16 * 7 1rem;
			}
		}

		&.NoHover:hover{
			border-color: $GreyL;
			background: transparent;
			color: $GreyDD;

			& span{
				border-color: transparent;
			}
		}

		&.ShowTouchSurface{
			border: 1px solid $Grey;
			white-space: nowrap;
		}

		&.LowPriority{
			&.NoHover:hover span{
				border-color: transparent;
			}
		}
	}

	.HelperButton{
		// The .Hover and .NoHover classes are purely for demo purposes on the styleguide.

		@include Breakpoint(Large, ExtraLarge){

			&.ShowTouchSurface{
				padding: 1rem / 16 * 7 1rem;
			}
		}

		&.NoHover:hover{
			color: $BrandBlueL;
			background: transparent;

			& span{
				border-color: transparent;
			}
		}

		&.ShowTouchSurface{
			border: 1px solid $Grey;
			white-space: nowrap;
		}

		&.LowPriority{
			&.NoHover:hover {
				color: $Grey;

				span{
					border-color: transparent;
				}
			}

			&:hover {
				color: $Grey;
			}
		}
	}

	.TouchButtonWrapper{
		display: flex;
		align-items: center;

		.Pointer{
			height: 2px;
			width: 1.5rem;
			margin: 0 1rem 0 0;
			background: $Grey;
		}

		.Details{
			margin-top: 0;
		}
	}

	.Details{
		margin-bottom: 0;
		margin-top: 10px;
	}

	.ButtonWrapper.DarkBackground{
		padding: 32px 24px;
		background: $GreyD;
		margin: 0;

		.State{
			color: $White;
		}
	}

	.IconButtonInfo{
		margin-bottom: 0;
	}

	.IconButtons{
		display: inline-flex !important;

		.Details{
			display: block;
			margin: 0 auto 0 auto;
			@include FontSize('SN');
		}
	}

	.IconButtonGroups{
		margin: 0 2rem 1rem 0;

		&:last-of-type{
			margin-right: 0;
		}
	}

	.IconButton{
		background: none;
		border: none;

		i{
			font-size: 20px;
		}

		&.Search{
			height: 40px;
			width: 40px;
			margin-right: 1rem;
			background: $BrandOrange;
			border-radius: 3px;

			&:hover:not(.NoHover),
			&.Hover{
				background: $BrandOrangeD;
			}

			.SearchIcon{
				color: $White;
			}
		}

		.HeartIcon{
			Color: $BrandOrange;

			&:hover:not(.NoHover),
			&.Hover{
				Color: $BrandOrangeD;
			}
		}

		.PencilIcon{
			Color: $BrandBlue;

			&:hover:not(.NoHover),
			&.Hover{
				Color: $BrandBlueD;
			}
		}
	}

	.ExampleLinks{
		color: $GreyDD;

		span:first-of-type{
			margin-bottom: 1rem;
		}

		.NonVisitedLink{
			color: $Blue !important;
		}

		.VisitedLink{
			color: $BrandBlue !important;
		}
	}

	.ButtonsGuidelines{
		margin: $SpaceL 0 0 0;
	}

	.ContentWrapper .SubItem .VisualBlock.DisplayBlock{
		display: block;
	}
