
	@import '../../../CSS/_variables.scss';

	#NavigationExample {
		max-width: 50rem;
		padding: $SpaceS;
		margin: $SpaceN 0;
		background: $GreyLL;
	}

	#ContentSwitcher {
		display: none;

		@include Breakpoint(Large, ExtraLarge) {
			display: block;
			margin: 0 0 1rem;
		}

		a {
			font-size: 1.5rem;
			color: $GreyD;
			cursor: pointer;
			display: inline-block;
			height: 30px;
			margin: 0 $SpaceS;
			background: none;
			@include FontSize('N');

			&.Active {
				color: $BrandBlue;
				border-bottom: 0.25rem $BrandBlue solid;
			}

			&:hover {
				text-decoration: none;
			}

			&:not(.Active):hover {
				border-bottom: 0.25rem $GreyL solid;
			}
		}
	}

	#ContentWrapper {

		.DrawerToggle {
			display: block;
			height: 60px;
			line-height: 60px !important;
			padding: 0 $SpaceS;
			margin-top: 1rem;
			@include HeadingSize('H3');
			color: $GreyDD;
			background: $White;
			cursor: pointer;

			@include Breakpoint(Large, ExtraLarge) {
				display: none;
			}

			&:first-child {
				margin-top: 0;
			}

			&:hover {
				text-decoration: none;
			}

			.FoldButton {
				float: right;
				position: relative;
				top: 50%;
				transform: translateY(-50%);

				@include Breakpoint(Large, ExtraLarge) {
					display: none;
				}
			}
		}

		div {
			display: none;
			padding: $SpaceS $SpaceS $SpaceN;
			background: $White;

			&.Active {
				display: block;
			}

			h2 {
				@include HeadingSize('H2');
			}
		}
	}

	.VisualBlock {
		display: flex;
		align-items: center;
		flex-flow: column nowrap;
		max-width: 50rem;
	}

	.Guidelines{
		margin: $SpaceL 0 0 0;
	}
