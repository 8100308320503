@import './../node_modules/@studyportals/styles-abstracts/abstracts';

body{
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	-webkit-text-size-adjust: none;
	color: $GreyDD;
	background-color: $White;

	h1, h2, h3, h4, h5, h6{
		margin: 0 0 0.25rem 0;
		color: $GreyDD;
		font-weight: normal;
	}

	h1{
		@include HeadingSize('H1');
	}

	h2{
		@include HeadingSize('H2');
	}

	h3{
		@include HeadingSize('H3');
	}

	h4{
		@include HeadingSize('H4');
	}

	h5{
		@include HeadingSize('H5');
	}

	h6{
		@include HeadingSize('H6');
	}

	/* Standard hyperlink styling. */
	a{
		color: $BrandBlue;
		text-decoration: none;

		&:hover{
			color: $BrandBlueD;
			text-decoration: underline;
		}

		&:visited{
			color: $BrandBlueD;
		}
	}

	p{
		margin: 0 0 0.75rem 0;
	}

	abbr{
		text-decoration: none;
		cursor: help;
		border-bottom: 1px dotted;

		/* Hack to not show a line on 'touch' devices (to avoid confusion since they won't hover to see more) */
		@include Breakpoint(Small, Medium){
			border-bottom: 0;
		}
	}
}