@import '../node_modules/@studyportals/styles-abstracts/abstracts'; // Importing the main SASS file.

$MaxContentWidth: 1200px;
$distance-between-blocks: 2rem;

// Spacing
$SpaceS: 1rem;
$SpaceN: 3rem;
$SpaceL: 4rem;
$SpaceXL: 6rem;
$SpaceXXL: 8rem;

// Colours
$StudentColour: $BrandOrange;
$ClientColour: $BrandBlue;
$StudentColourL: $BrandOrangeL;
$ClientColourL: $BrandBlueL;
$StudentColourD: $BrandOrangeD;
$ClientColourD: $BrandBlueD;

// Font-weight
$Regular: normal;
$Semibold: 600;

