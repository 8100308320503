
	@import '../../../CSS/_variables.scss';

	#Imagery{

		.HeroList{
			margin: 0.5rem 0 0 0;
			padding-left: 1.1rem;

		}


		.HeroImages{

			.HeroImg{
				margin-bottom: 3rem;

				&:nth-last-child(2){
					margin-bottom: 0;
				}
			}
			.BachelorHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/BAHeroA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/BAHeroA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.MasterHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/MAHeroA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/MAHeroA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.PhDHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/PhDHeroA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/PhDHeroA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.ShortHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroShortA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroShortA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.PrepHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroPrepA.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/HeroPrepA.png?q=60&auto=format&w=10&w=1280");
				}
			}

			.DistanceHero{
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/DLHeroB.png?q=60&auto=format&w=768");

				@include Breakpoint(ExtraLarge, Large){
					content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/DLHeroB.png?q=60&auto=format&w=10&w=1280");
				}
			}
		}

		.Dos{
			content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDos.jpg?q=60&auto=format&w=768");

			@include Breakpoint(ExtraLarge, Large){
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDos.jpg?q=60&auto=format&w=10&w=1280");
			}
		}

		.Donts{
			content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDonts.jpg?q=60&auto=format&w=768");

			@include Breakpoint(ExtraLarge, Large){
				content: url("//monet-prtl-co.imgix.net/StyleGuide/Imagery/ImageryDonts.jpg?q=60&auto=format&w=10&w=1280");
			}
		}
	}

