
	@import '../../../CSS/_variables.scss';

	#Typography{
		width: 100%;

		h3{
			margin-bottom: $SpaceL;
		}

		h4{
			margin-bottom: $SpaceS;
		}

		ul{
			margin: 0;
		}

		.HeadingList{
			list-style: none;
			width: 100%;
			padding: 0;

			li{
				display: block;
				width: auto;

				@include Breakpoint(Large, ExtraLarge) {
					display: flex;
				}

				.HeadingsDescription{
					min-width: 12rem;

					span{
						vertical-align: middle;
					}
				}

				.HeadingsProperties{

					span{
						vertical-align: middle;
					}
				}

				&.HeadingMain{

					// Extra note for this particular heading styling.
					.HeadingNote{
						font-weight: 400;
						color: $GreyD;
					}
				}

				&.HeadingAlternative{
					@include FontSize('SN');
					color: $GreyDD;
					margin: 0;
					padding-left: 1.5rem;

					.HeadingsDescription{
						min-width: 10.5rem; // 12 (min-width) - 1.5 (left padding)
						color: $GreyD;
					}
				}
			}
		}

		.FirstHeading{
			@include HeadingSize('H1');
		}

		.SuperHero{
			@include HeadingSize('H1-hero');
		}

		.LandingHero{
			@include HeadingSize('H1-cover');
		}

		.SecondHeading{
			@include HeadingSize('H2');
		}

		.ThirdHeading{
			@include HeadingSize('H3');
		}

		.FourthHeading{
			@include HeadingSize('H4');
		}

		.FifthHeading{
			@include HeadingSize('H5');
		}

		.SixthHeading{
			@include HeadingSize('H6');
		}
	}

	#Typeface{
		width: 100%;
		max-width: 100%;

		.TypefaceExample{
			margin-top: $SpaceN;
			width: 24rem;

			.AlphabetTitle{
				width: 6rem;
				margin-bottom: 2rem;
			}

			.Alphabet{
				width: 20rem;
			}
		}
	}

	#MainPageHeadings{
		width: 100%;
		min-width: 100%;

		.HeadingList{

			// Extra margin to distinguish groups of headings.
			margin-top: $SpaceN;

			li{
				.HeadingsDescription span, .HeadingsProperties span{
					line-height: 3.5rem;
				}

				&.HeadingMain{
					margin-top: 2rem;
				}
			}

			// Use a different line-height for the two biggest font-sizes to ensure vertical centering works.
			@include Breakpoint(Large, ExtraLarge) {
				li {
					&:nth-child(2) {
						line-height: 6rem;
					}

					&:nth-child(3) {
						line-height: 4rem;
						// After the last main heading deviation the headlines start, so create some extra spacing.
						margin-bottom: $SpaceN;
					}
				}
			}
		}
	}

	#BodyText{

		h3{
			// Compensate for the spacing created by vertically centering the first element.
			margin-bottom: calc(#{$SpaceL} - 0.5rem);
		}

		.HeadingList{

			li{
				.HeadingsDescription span, .HeadingsProperties span{
					line-height: 2.5rem;
				}
			}

			.ContentBody{
				@include FontSize('N');
			}

			.ContentBodyBold{
				@include HeadingSize('H6');
			}

			.ContentFootnote{
				@include FontSize('SN');
			}

			.ContentLabel{
				@include FontSize('S');
			}
		}
	}

	#ExampleTypography{

		.TagWrapper{
			margin-bottom: 1rem;
			display: flex;
			align-items: stretch;

			.TagContent{
				color: $GreyDD;
				margin-bottom: 0.5rem;
				padding-right: 2.5rem;
				display: inline-block;
				flex-basis: 70%;

				span:first-child{
					display: block;
					margin-bottom: 0.5rem;
				}
			}

			h3, h4{
				margin-bottom: 0.5rem;
				max-width: 30rem;
			}

			.TagDescription{
				display: flex;
				color: $GreyDD;
				border-left: 5px solid $GreyDD;
				padding: 0 0 0 2rem;
				flex-basis: 30%;

				span{
					align-self: center;
				}
			}
		}
	}
