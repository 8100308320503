
	@import '../../../CSS/_variables.scss';

	#Spacing{
		.VisualBlock{
			margin: 3rem 0 0;
		}
		.SpacingImage{
			width: 100%;
		}
		.PackageLinks {
			margin-top: 2rem;

			.PackageLinkWrapper {
				margin-right: 1rem;
			}
		}
		.ExampleIntro{
			width: 100%;
			text-align: center;
			margin: 1rem;
		}
	}
