
	@import '../../../../CSS/_variables.scss';

	#StyleSwitcher{
		padding: 0;

		.StyleSwitcherList{
			display: flex;
			justify-content: flex-end;
			margin: 0;
			padding: 0;
			background: $White;
		}

		.StyleSwitcherItem{
			margin: 1rem .5rem 0;
			list-style: none;
		}

		.SwitcherButton{
			display: inline-block;
			position: relative;
			height: 3rem;
			width: 2rem;
			color: $GreyDD;
			font-size: 1.4rem;
			line-height: 2.5rem;
			text-align: center;
			text-decoration: none;
			cursor: pointer;
			box-sizing: border-box;

			.SwitcherButtonIcon{
				vertical-align: middle;
			}

			.SwitcherButtonLabel{
				display: none;
				position: absolute;
				top: -1rem;
				left: -50%;
				width: 4rem;
				text-align: center;

				@include FontSize('N');
			}

			&:hover{
				text-decoration: none;
				border-bottom: 0.25rem solid $GreyL;
			}

			&.is-Active{
				cursor: default;
				pointer-events: none;
				border-bottom: 0.25rem solid $GreyDD;

				.SwitcherButtonLabel{
					display: block;
				}
			}
		}
	}
