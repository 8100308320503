
	@import '../../../../CSS/_variables.scss';

	#Colour{
		font-size: 0;

		.ColourModeHeader{
			display: inline-block;
		}

		.ColourWrapper{
			margin-top: 1rem;
			flex: 1;
			box-sizing: border-box;

			&.PrimaryColours{

				@include Breakpoint(Small, Medium){

					&:last-child{
						padding-top: 2rem;
					}
				}
			}

			&.SecondaryColours{
				flex: 3;
				margin: 1rem;

				/* Only first three need bottom spacing */
				&:nth-child(-n+3){
					margin-bottom: 1rem;
				}
			}
		}

		.ColourGroup{
			display: flex;
			flex-direction: row;

			.ColourGroupWrapper {
				align-self: flex-end;
				box-sizing: border-box;
			}

			.ColourPair{
				display: flex;
				flex-direction: column;
				box-sizing: border-box;

				.Colour{
					display: flex;
					align-items: center;
					margin-top: 0.5rem;
				}
			}

			.PrimaryColourPair{

				@include Breakpoint(Small){
					display: none;
				}
			}
		}

		.PrimaryColorGroup{

			@include Breakpoint(Small){
				flex-wrap: wrap;
			}

			.ColourPair{

				padding-top: 1rem;
			}
		}
	}
