/**
 * @file Default.scss
 *
 * Basic styling for the style guide.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Alexandra Marginean <alexia@studyportals.com>
 * @author Stanley Clark <stanley@studyportals.com>
 * @version 1.1.4
 * @copyright © 2016 - 2018 Studyportals B.V., all rights reserved.
 */

@import 'variables'; // Importing the main Sass file.
@import 'Galactus';

/**
 * These font sizes are temporarily defined here. They should be implemented elsewhere eventually.
 */
.Pane {
	max-width: $MaxContentWidth;
}

body {
	overflow-x: hidden;

	h2 {
		@include HeadingSize('H2');

		margin-bottom: 1rem;
	}

	h3 {
		@include HeadingSize('H3');
	}

	h4 {
		@include HeadingSize('H4');
	}

	p {
		max-width: 900px;
	}

	strong, b{
		font-weight: 600;
	}
	
	ul > li, ol > li{
		line-height: 1.5rem;
	}
}

.SingleColumn {
	max-width: 800px;
	margin: 1rem auto;
	padding: 2rem;
	background: $White;
}

.LastUpdate {
	display: block;
	margin-bottom: 0.5rem;
	font-style: italic;
}

#text-samples {
	clear: both;
}

.example {
	background-color: $GreyLLL;
	padding: 1rem;
}

.ContentWrapper {
	padding: $SpaceXL;

	.SubItem{
		max-width: 60rem;

		> p, > span, > dl{
			&:first-of-type{
				display: block;
				margin-top: $SpaceL;
			}
		}

		p{
			@include FontSize('N');
			max-width: 30rem;
		}

		.VisualBlock{
			margin-top: $SpaceN;
			display: flex;
			flex-flow: row wrap;

			figcaption{
				margin-top: $SpaceS;
				text-align: center;
				max-width: 50rem;

				@include FontSize('SN');
			}
		}
	}
}

.ImageCaption{
	width: 100%;
	max-width: 100%;
	text-align: center;
	@include FontSize('SN');
}

.Vertical{
	flex-direction: column !important;
}

.Wrapper{
	display: flex;
	flex-wrap: wrap;
}

//Styling for component sections
.States{
	margin-bottom: 1rem;

	.SecondaryText {
		color: $GreyD;
	}
}

.ComponentGroup{
	margin: 0 0 0 -3rem;
}

.ComponentWrapper{
	display: flex;
	flex-direction: column;
	margin: 0 0 1rem 3rem;
	align-items: center;
}
	
.State{
	margin-top: 0.5rem;

	@include FontSize('SN');
}

//Sadly grid styling is so specific I had to use !important for this :'(
.SubItem {
	margin-top: $SpaceXL !important;

	&:first-child {
		margin-top: 0 !important;
	}
}

.HeaderHasSelect{
	line-height: 3rem;
	margin-bottom: 0.5rem;
}

.PageSettingSelect{
	display: inline-block;
	height: 3rem;
	padding: 0.5rem;
	margin-left: 1rem;
	border-radius: 3px;
	@include HeadingSize('H5');
	line-height: 1;
	font-weight: normal;
	border-color: $GreyL;
	cursor: pointer;
	vertical-align: top;
}
