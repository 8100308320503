
	@import '../../../../CSS/_variables.scss';

	.DownloadLogo{
		min-width: 8rem;
		margin-top: 3rem;
		margin-bottom: 3rem;

		.DownloadLogoWrapper{
			margin-bottom: 1rem;
			position: relative;
			overflow: hidden;

			&.WrapperBorder{
				border: 1px solid $Grey;
			}
		}

		.DownloadHasFile{
			&:hover{
				.DownloadControls{
					display: block;
				}
				.DownloadImage{
					opacity: 0.5;
					transition: opacity 500ms;
				}
			}
		}

		.DownloadControls{
			display: none;
			padding: 0.5rem 1rem;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			border: 1px solid $BrandBlue;
			border-radius: 0.2rem;
			background-color: $White;
			@include FontSize('SN');

			.DownloadLink{
				display: inline;
			}

			.DownloadLabel{
				margin-left: 0.2rem;
			}

			.DownloadTypes{
				@include FontSize('SN');
			}
		}

		.LogoDescription{
			margin-bottom: 1rem;
			text-align: center;
			max-width: none;

			@include FontSize('SN');
		}
	}
