
	@import '../../../CSS/_variables.scss';

	#Icons{

		.IconsModeHeader{
			display: inline-block;
		}

		.IconBlock{
			position: relative;
			display: inline-block;
			text-align: center;
			width: 7.5rem;
			vertical-align: top;
			margin-bottom: 2rem;

			.Icon{
				display: block;
				font-size: 2rem;
				padding: 0.25rem;
				margin: 0 auto;
			}

			.IconLabel{
				display: block;
				margin-top: 0.5rem;
				line-height: 1.5rem;
				height: 3rem;
				@include FontSize('N');

				&.Invisible{
					visibility: hidden;
				}
			}

			.CustomLabel{
				position: absolute;
				background-color: $Yellow;
				color: $White;
				font-size: 0.6rem;
				line-height: 1rem;
				padding: 0 0.1rem;
				left: 1.25rem;
			}
		}
	}
